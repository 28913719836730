import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'

const Input = (
  props: {
    fileData: any,
    isEdit: boolean,
    setIsEdit: (isEdit: boolean) => void,
    setFileData: (fileData: any) => void,
    loadData: () => void
    setLoadfile: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
  }) => {
  const { fileData, isEdit, setIsEdit, setFileData, loadData, setLoadfile } = props;
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState({
    files: Array(6).fill(null) as (File | null)[],
    name: ''
  })
  const [errors, setErrors] = useState<string[]>(Array(6).fill(''))
  const [btnLoading, setBtnLoading] = useState(false)
  const [nameFocused, setNameFocused] = useState(false)
  const [nameError, setNameError] = useState('')
  useEffect(() => {
    if (isEdit && fileData?.files) {
      const loadFilesFromUrls = async () => {
        setLoadfile(prev => ({ ...prev, [fileData?.id]: true }))
        const loadedFiles = await Promise.all(
          fileData.files.map(async (file: any) => {
            if (!file?.url) return null;
  
            // ดาวน์โหลดไฟล์จาก URL
            const response = await fetch(process.env.REACT_APP_API_URL + `fetchFileFromUrl?url=${encodeURIComponent(file.url)}`);
            if (!response.ok) throw new Error('Failed to fetch file');
            const blob = await response.blob();
  
            // สร้าง File object จาก Blob
            return new File([blob], file.filename, { type: file.mimetype });
          })
        );
        setLoadfile(prev => ({ ...prev, [fileData?.id]: false }))
        // อัปเดต state ด้วยไฟล์ที่โหลดมา
        setFormData({ files: loadedFiles.filter(Boolean), name: fileData.name });
        // เปิดฟอร์มหรือหน้าจอแก้ไข
        open();
      };
      loadFilesFromUrls();
    }
  }, [isEdit, fileData, setLoadfile]);

  const open = () => {
    setIsOpen(true)
  }

  const handleSave = async () => {
    setErrors(Array(6).fill(''))
    setNameError('')
    setBtnLoading(true)

    let hasError = false;

    if (!formData.name.trim()) {
      setNameError('กรุณาระบุชื่อชุดสลิป')
      setBtnLoading(false);
      document.getElementById('slipName')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    const missingFiles = formData.files.map((file, index) => {
      if (!file) {
        setErrors(prev => {
          const newErrors = [...prev]
          newErrors[index] = 'กรุณาอัพโหลดรูปภาพ'
          return newErrors
        })
        hasError = true;
        return true;
      }
      return false;
    })

    if (hasError) {
      setBtnLoading(false);
      // Find first missing file index
      const firstMissingIndex = missingFiles.findIndex(missing => missing);
      if (firstMissingIndex !== -1) {
        document.getElementById(`file-input-${firstMissingIndex}`)
          ?.closest('.space-y-2')
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    try {
      const filePromises = formData.files.map(async (file) => {
        if (!file) return null;
        const base64 = await convertFileToBase64(file);
        return {
          name: file.name,
          base64: base64,
          type: file.type
        };
      });

      const processedFiles = await Promise.all(filePromises);
      const validFiles = processedFiles.filter(file => file !== null);

      const endpoint = isEdit
        ? `${process.env.REACT_APP_API_URL}updateSlip/${fileData.id}`
        : `${process.env.REACT_APP_API_URL}addSlip`;

      const response = await fetch(endpoint, {
        method: isEdit ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ files: validFiles, name: formData.name })
      });

      const json = await response.json();

      if (json.success) {
        Swal.fire({
          title: isEdit ? 'แก้ไขสลิปสำเร็จ' : 'เพิ่มสลิปสำเร็จ',
          icon: 'success'
        }).then(() => {
          loadData();
          handleClose();
        });
      } else {
        Swal.fire({
          title: 'เกิดข้อผิดพลาด!',
          text: json.msg || 'ไม่สามารถอัพโหลดไฟล์ได้',
          icon: 'error'
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: 'เกิดข้อผิดพลาด!',
        text: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์',
        icon: 'error'
      });
    } finally {
      setBtnLoading(false)
    }
  };

  const nameSlip = [
    'สลิปโอนเงิน(TH)',
    'สลิปโอนเงิน(EN)',
    'สลิปโอนเงินบันทึกช่วยจำ(TH)',
    'สลิปโอนเงินบันทึกช่วยจำ(EN)',
    'สลิปจ่ายบิล(TH)',
    'สลิปจ่ายบิล(EN)'
  ]

  const handleFileChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

      if (!validImageTypes.includes(file.type)) {
        alert('กรุณาอัพโหลดไฟล์รูปภาพเท่านั้น (.jpg, .png, .gif, .webp)');
        e.target.value = '';
        return;
      }

      const newFiles = [...formData.files];
      newFiles[index] = file;
      setFormData({ ...formData, files: newFiles });
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    setFormData({ files: Array(6).fill(null), name: '' })
    setErrors(Array(6).fill(''))
    setIsEdit(false)
    setFileData(null)
  }

  const handleFocus = (setFocus: (value: boolean) => void) => () => {
    setFocus(true)
  }

  const handleBlur = (setFocus: (value: boolean) => void) => () => {
    setFocus(false)
  }

  return (
    <>
      <button
        className="linear mb-5 mt-4 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-red-700"
        onClick={open}
      >
        เพิ่มสลิป
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center overflow-hidden">
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-300 ease-in-out"
          />
          <div
            className="relative w-full max-w-md max-h-[90vh] transform rounded-lg bg-white p-6 shadow-lg transition-all duration-300 ease-in-out animate-slideUp overflow-y-auto"
          >
            <div className="mb-4 flex items-center justify-between">
              <h3 className="text-lg font-medium">{isEdit ? 'แก้ไขสลิป' : 'เพิ่มสลิป'}</h3>
              <button
                onClick={handleClose}
                className="rounded-full p-1 hover:bg-gray-100"
              >
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="space-y-4">
              <div className="relative">
                <input
                  type="text"
                  id="slipName"
                  value={formData.name}
                  onFocus={handleFocus(setNameFocused)}
                  onBlur={handleBlur(setNameFocused)}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, name: e.target.value }))
                    setNameError('')
                  }}
                  className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none 
                    ${nameError ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'focus:border-red-500 focus:ring-red-500'}`}
                />
                <label
                  htmlFor="slipName"
                  className={`absolute left-3 bg-white px-1 transition-all duration-200 ease-in-out 
                    ${nameFocused || formData.name ? "left-2 top-[-0.6rem] text-xs" : "left-3 top-[0.85rem] text-sm"}
                    ${nameError ? 'text-red-500' : ''}`}
                >
                  ชื่อชุดสลิป
                </label>
                {nameError && (
                  <p className="mt-1 text-xs text-red-500">
                    {nameError}
                  </p>
                )}
              </div>
              {[...Array(6)].map((_, index) => (
                <div key={index} className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    {nameSlip[index]}
                  </label>
                  <div className="relative">
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif, image/webp"
                      onChange={handleFileChange(index)}
                      className="hidden"
                      id={`file-input-${index}`}
                    />
                    <label
                      htmlFor={`file-input-${index}`}
                      className={`relative flex items-center justify-center w-full p-2 border-2 border-dashed rounded-lg cursor-pointer transition-colors duration-200 ${errors[index]
                        ? 'border-red-500 hover:border-red-600'
                        : 'border-gray-300 hover:border-red-500'
                        }`}
                    >
                      {formData.files[index] ? (
                        <div className="w-full h-32">
                          <img
                            src={URL.createObjectURL(formData.files[index]!)}
                            alt={`Preview ${index + 1}`}
                            className="w-full h-full object-contain"
                          />
                          <div className="absolute bottom-2 left-2 right-2 text-xs text-gray-500 bg-white/80 p-1 rounded">
                            {formData.files[index]?.name}
                          </div>
                        </div>
                      ) : (
                        <div className="space-y-1 text-center">
                          <svg
                            className="mx-auto h-8 w-8 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <div className="text-xs text-gray-600">
                            <span className="text-red-500 font-medium">อัพโหลดรูปภาพ</span> หรือลากไฟล์มาวาง
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF, WEBP ไม่เกิน 10MB
                          </p>
                        </div>
                      )}
                    </label>
                    {errors[index] && (
                      <p className="mt-1 text-xs text-red-500">
                        {errors[index]}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={handleSave}
                disabled={btnLoading}
                className="rounded-md bg-red-500 px-4 py-2 text-white transition-colors duration-200 ease-in-out hover:bg-red-600 active:bg-red-700 disabled:bg-red-300 disabled:cursor-not-allowed flex items-center"
              >
                {btnLoading ? (
                  <div className="flex items-center">
                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                ) : 'บันทึก'}
              </button>
              <button
                onClick={handleClose}
                className="rounded-md bg-gray-100 px-4 py-2 transition-colors duration-200 ease-in-out hover:bg-gray-200 active:bg-gray-300"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

// Utility function สำหรับแปลงไฟล์เป็น base64
const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      // ตัดส่วน "data:image/jpeg;base64," ออกถ้าต้องการเฉพาะ base64 string
      const base64 = base64String.split(',')[1];
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
  });
};

export default Input;
