import { useState, useEffect } from "react";
import Card from "components/card";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Swal from "sweetalert2";
import Table from "components/table/Table";

type FileItem = {
  filename: string;
  url: string;
  mimetype: string;
  _id: string;
  uploadedAt: string;
};

type FileData = {
  _id: string;
  files: FileItem[];
  name: string;
  __v: number;
};

const columnHelper = createColumnHelper<FileData>();

const showLargeImage = (url: string, filename: string) => {
  Swal.fire({
    imageUrl: url,
    imageAlt: filename || 'Slip image',
    width: 'auto',
    imageWidth: 'auto',
    imageHeight: '90vh',
    showCloseButton: true,
    showConfirmButton: false,
    customClass: {
      image: 'object-contain max-w-full',
    },
  });
};

function CheckTable(
  props: {
    tableData: FileData,
    handleEdit: (id: string) => void,
    loadFile: { [key: string]: boolean }
  }) {
  const { tableData, handleEdit, loadFile } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<FileData[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(50);

  useEffect(() => {
    if (tableData && Array.isArray(tableData)) {
      setData(tableData);
    } else {
      setData([]);
    }
  }, [tableData]);

  const handleButtonClick = async (id: string, name: string) => {
    // Show confirmation modal first with loading state
    const result = await Swal.fire({
      title: 'ยืนยันการลบ',
      text: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#666666',
      confirmButtonText: 'ลบ',
      cancelButtonText: 'ยกเลิก',
      preConfirm: async () => {
        Swal.showLoading();
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + "deleteSlip", {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: id,
              name: name,
            }),
          });

          const json = await response.json();
          if (json.status === "success") {
            return true;
          }
          throw new Error();
        } catch (error) {
          Swal.showValidationMessage('ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้');
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });

    if (result.isConfirmed) {
      await Swal.fire({
        title: "เรียบร้อย",
        text: "ลบรายการสำเร็จ",
        icon: "success",
        confirmButtonText: "ปิด",
      });
      window.location.href = "/admin/slip-list";
    }
  };

  const handleEditWithLoading = async (id: string) => {
    await handleEdit(id);
  };

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          ชื่อชุดสลิป
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("files", {
      id: "slip_one",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          สลิปโอนเงิน(TH)
        </p>
      ),
      cell: (info) => {
        const filesData = info.getValue();
        const firstFile = Array.isArray(filesData) && filesData[0];

        if (!firstFile) return null;

        return (
          <div className="flex flex-wrap gap-2 justify-center">
            <img
              key={firstFile._id}
              src={firstFile.url} // ใช้ URL แทน base64
              alt={firstFile.filename || "Slip image"}
              className="h-16 w-16 object-cover rounded cursor-pointer"
              onClick={() =>
                showLargeImage(firstFile.url, firstFile.filename)
              } // ส่ง URL ไปแสดงภาพขยาย
              onError={(e) => {
                console.error("Image loading error:", e);
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("files", {
      id: "slip_two",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          สลิปโอนเงิน(EN)
        </p>
      ),
      cell: (info) => {
        const filesData = info.getValue();
        const secondFile = Array.isArray(filesData) && filesData[1];

        if (!secondFile) return null;

        return (
          <div className="flex flex-wrap gap-2 justify-center">
            <img
              key={secondFile._id}
              src={secondFile.url} // ใช้ URL แทน base64
              alt={secondFile.filename || "Slip image"}
              className="h-16 w-16 object-cover rounded cursor-pointer"
              onClick={() =>
                showLargeImage(secondFile.url, secondFile.filename)
              } // ส่ง URL ไปแสดงภาพขยาย
              onError={(e) => {
                console.error("Image loading error:", e);
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("files", {
      id: "slip_three",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          สลิปโอนเงินบันทึกช่วยจำ(TH)
        </p>
      ),
      cell: (info) => {
        const filesData = info.getValue();
        const thirdFile = Array.isArray(filesData) && filesData[2];

        if (!thirdFile) return null;

        return (
          <div className="flex flex-wrap gap-2 justify-center">
            <img
              key={thirdFile._id}
              src={thirdFile.url} // ใช้ URL แทน base64
              alt={thirdFile.filename || "Slip image"}
              className="h-16 w-16 object-cover rounded cursor-pointer"
              onClick={() =>
                showLargeImage(thirdFile.url, thirdFile.filename)
              } // ส่ง URL ไปแสดงภาพขยาย
              onError={(e) => {
                console.error("Image loading error:", e);
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("files", {
      id: "slip_four",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          สลิปโอนเงินบันทึกช่วยจำ(EN)
        </p>
      ),
      cell: (info) => {
        const filesData = info.getValue();
        const fourthFile = Array.isArray(filesData) && filesData[3];

        if (!fourthFile) return null;

        return (
          <div className="flex flex-wrap gap-2 justify-center">
            <img
              key={fourthFile._id}
              src={fourthFile.url} // ใช้ URL แทน base64
              alt={fourthFile.filename || "Slip image"}
              className="h-16 w-16 object-cover rounded cursor-pointer"
              onClick={() =>
                showLargeImage(fourthFile.url, fourthFile.filename)
              } // ส่ง URL ไปแสดงภาพขยาย
              onError={(e) => {
                console.error("Image loading error:", e);
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("files", {
      id: "slip_five",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          สลิปจ่ายบิล(TH)
        </p>
      ),
      cell: (info) => {
        const filesData = info.getValue();
        const fifthFile = Array.isArray(filesData) && filesData[4];

        if (!fifthFile) return null;

        return (
          <div className="flex flex-wrap gap-2 justify-center">
            <img
              key={fifthFile._id}
              src={fifthFile.url} // ใช้ URL แทน base64
              alt={fifthFile.filename || "Slip image"}
              className="h-16 w-16 object-cover rounded cursor-pointer"
              onClick={() =>
                showLargeImage(fifthFile.url, fifthFile.filename)
              } // ส่ง URL ไปแสดงภาพขยาย
              onError={(e) => {
                console.error("Image loading error:", e);
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("files", {
      id: "slip_six",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          สลิปจ่ายบิล(EN)
        </p>
      ),
      cell: (info) => {
        const filesData = info.getValue();
        const sixthFile = Array.isArray(filesData) && filesData[5];

        if (!sixthFile) return null;

        return (
          <div className="flex flex-wrap gap-2 justify-center">
            <img
              key={sixthFile._id}
              src={sixthFile.url} // ใช้ URL แทน base64
              alt={sixthFile.filename || "Slip image"}
              className="h-16 w-16 object-cover rounded cursor-pointer"
              onClick={() =>
                showLargeImage(sixthFile.url, sixthFile.filename)
              } // ส่ง URL ไปแสดงภาพขยาย
              onError={(e) => {
                console.error("Image loading error:", e);
              }}
            />
          </div>
        );
      },
    }),
    columnHelper.accessor("files", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white text-center">
          Action
        </p>
      ),
      cell: (info) => (
        <div className="flex gap-2 flex-col justify-center items-center">
          <button
            className="w-20 rounded-lg bg-gray-600 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-700 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
            onClick={() => handleEditWithLoading(info.row.original._id)}
          >
            {loadFile[info.row.original._id] ? (
              <div className="flex justify-center items-center">
                <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            ) : 'แก้ไข'}
          </button>
          <button
            className="w-20 rounded-lg bg-red-600 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-red-700 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
            onClick={() => handleButtonClick(info.row.original._id, info.row.original.name)}
          >
            ลบ
          </button>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const safeData = Array.isArray(data) ? data : [];
  const pageCount = Math.ceil(safeData.length / pageSize);
  const currentPageData = safeData.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const handlePreviousFivePage = () => {
    setCurrentPage((prev) => Math.max(prev - 5, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1));
  };

  const handleNextFivePage = () => {
    setCurrentPage((prev) => Math.min(prev + 5, pageCount - 1));
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (pageCount <= maxPagesToShow + 1) {
      for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`h-8 w-8 text-sm font-bold ${i === currentPage
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      if (currentPage < maxPagesToShow - 1) {
        for (let i = 0; i < maxPagesToShow; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`h-8 w-8 text-sm font-bold ${i === currentPage
                ? "rounded-lg bg-blue-600 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
                }`}
            >
              {i + 1}
            </button>
          );
        }

        pageNumbers.push(
          <button
            key="ellipsis1"
            onClick={handleNextFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = ">>")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        pageNumbers.push(
          <button
            key={pageCount - 1}
            onClick={() => handlePageClick(pageCount - 1)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === pageCount - 1
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            {pageCount}
          </button>
        );
      } else if (currentPage >= pageCount - maxPagesToShow + 1) {
        pageNumbers.push(
          <button
            key={0}
            onClick={() => handlePageClick(0)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === 0
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            1
          </button>
        );

        pageNumbers.push(
          <button
            key="ellipsis2"
            onClick={handlePreviousFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = "<<")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        for (let i = pageCount - maxPagesToShow; i < pageCount; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`h-8 w-8 text-sm font-bold ${i === currentPage
                ? "rounded-lg bg-blue-600 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
                }`}
            >
              {i + 1}
            </button>
          );
        }
      } else {
        pageNumbers.push(
          <button
            key={0}
            onClick={() => handlePageClick(0)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === 0
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            1
          </button>
        );

        pageNumbers.push(
          <button
            key="ellipsis3"
            onClick={handlePreviousFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = "<<")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`h-8 w-8 text-sm font-bold ${i === currentPage
                ? "rounded-lg bg-blue-600 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
                }`}
            >
              {i + 1}
            </button>
          );
        }

        pageNumbers.push(
          <button
            key="ellipsis4"
            onClick={handleNextFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = ">>")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        pageNumbers.push(
          <button
            key={pageCount - 1}
            onClick={() => handlePageClick(pageCount - 1)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === pageCount - 1
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            {pageCount}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <Card extra={"w-full px-6"}>
      <div className="overflow-x-auto xl:overflow-x-hidden">
        {!Array.isArray(data) || data.length === 0 ? (
          <div className="flex justify-center items-center py-8">
            <p className="text-gray-500 dark:text-gray-400">ไม่พบข้อมู</p>
          </div>
        ) : (
          <>
            <Table
              table={table}
              currentPage={currentPage}
              pageSize={pageSize}
              currentPageData={currentPageData}
            />
            <div className="sticky bottom-0 bg-white my-4 flex justify-center">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 0}
                className={`h-8 w-8 rounded-l-full bg-gray-100 text-xl dark:text-white ${currentPage === 0
                  ? "text-gray-600"
                  : "text-navy-700 hover:bg-gray-300"
                  }`}
              >
                {"<"}
              </button>
              <div className="flex bg-gray-100">{renderPageNumbers()}</div>
              <button
                onClick={handleNextPage}
                disabled={currentPage === pageCount - 1}
                className={`h-8 w-8 rounded-r-full bg-gray-100 text-xl dark:text-white ${currentPage === pageCount - 1
                  ? "text-gray-600"
                  : "text-navy-700 hover:bg-gray-300"
                  }`}
              >
                {">"}
              </button>
            </div>
          </>
        )}
      </div>
    </Card>
  );
}

export default CheckTable;
