import {
  flexRender,
  Table as ReactTable,
} from "@tanstack/react-table";

interface TableProps<T> {
  table: ReactTable<T>;
  currentPage: number;
  pageSize: number;
  currentPageData: T[];
}

function Table<T>({ table, currentPage, pageSize, currentPageData }: TableProps<T>) {
  return (
    <table className="w-96">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="!border-px !border-gray-400">
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                onClick={header.column.getToggleSortingHandler()}
                className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
              >
                <div className="items-center justify-between text-xs text-gray-200">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {{
                    asc: "",
                    desc: "",
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {currentPageData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {table
              .getRowModel()
              .rows.slice(
                currentPage * pageSize,
                (currentPage + 1) * pageSize
              )
              [rowIndex].getVisibleCells()
              .map((cell) => (
                <td
                  key={cell.id}
                  className="min-w-[150px] border-white/0 py-3 pr-4"
                >
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  )}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table; 