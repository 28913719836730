import { useEffect, useState } from "react";
import getData from "services/database";
import CheckTable from "./components/CheckTable";
import InputFixUser from "./components/InputAddSlip";

const AddSlip = () => {
  const [records, setRecords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fileData, setFileData] = useState<any>(null);
  const [loadFile, setLoadfile] = useState<{ [key: string]: boolean }>({});

  const handleEdit = async (id: string) => {
    setIsEdit(true);
    return getData.getFileSlipById(id).then((data) => {
      setFileData(data);
    });
  }

  const loadData = () => {
    setIsLoading(true);
    getData.getFileSlip()
      .then((data) => {
        setRecords(data.files ?? []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="mx-auto mt-3 flex h-full items-center justify-center">
      <div className="grid h-full grid-cols-1">
        <div className="col-span-1 mx-auto h-full w-full rounded-xl sm:w-96 md:w-96 lg:w-96 xl:w-96 2xl:col-span-1">
          <InputFixUser
            fileData={fileData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setFileData={setFileData}
            loadData={loadData}
            setLoadfile={setLoadfile}
          />
        </div>
        <div className="grid grid-cols-1 gap-5 xl:grid-cols-1">
          <div>
            {isLoading ? (
              <div className="flex justify-center items-center p-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <CheckTable
                tableData={records}
                handleEdit={handleEdit}
                loadFile={loadFile}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSlip;
