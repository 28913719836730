import { useState, useEffect } from "react";
import Card from "components/card";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash, FaClipboard, FaCheck } from "react-icons/fa";
import dayjs from "dayjs";
import Table from "components/table/Table";

const columnHelper = createColumnHelper<User>();

type User = {
  runNumber: string;
  count: number;
  apiKey: string;
  trialEndDate: string;
};

function CheckTable(props: { tableData: User[] }) {
  const { tableData } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(50);
  const [showApiKey, setShowApiKey] = useState<Record<string, boolean>>({});
  const [copiedState, setCopiedState] = useState<{ [key: string]: boolean }>({});


  const handleCopyKey = (runNumber: string, apiKey: string) => {
    navigator.clipboard.writeText(apiKey);

    // Set copied state to true for the specific runNumber
    setCopiedState((prevState) => ({ ...prevState, [runNumber]: true }));

    // Reset copied state for the specific runNumber after 2 seconds
    setTimeout(() => {
      setCopiedState((prevState) => ({ ...prevState, [runNumber]: false }));
    }, 2000);
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const handleButtonClick = async (runNumber: string) => {
    try {
      fetch(process.env.REACT_APP_API_URL + "resetTrial", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          runNumber: runNumber,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "success") {
            Swal.fire({
              title: "เรียบร้อย",
              text: "",
              icon: "success",
              confirmButtonText: "ปิด",
            }).then(() => {
              window.location.href = "/admin/generate-key";
            });
            return;
          } else if (json.data === "ไม่พบรันนัมเบอร์นี้") {
            Swal.fire({
              title: "เกิดข้อผิดพลาด!",
              text: "ไม่พบรันนัมเบอร์นี้",
              icon: "error",
              confirmButtonText: "ปิด",
            });
            return;
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด!",
              text: "ผู้ทดลองยังใช้งานไม่ครบจำนวนการใช้งาน",
              icon: "error",
              confirmButtonText: "ปิด",
            });
            return;
          }
        });
    } catch (error) {
      console.error("Error sending username to API:", error);
    }
  };

  const toggleApiKeyVisibility = (runNumber: string) => {
    setShowApiKey((prevState) => ({
      ...prevState,
      [runNumber]: !prevState[runNumber],  // เปลี่ยนสถานะการแสดง API Key
    }));
  };

  const columns = [
    columnHelper.accessor("runNumber", {
      id: "runNumber",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white sm:pr-0 lg:pr-[300px] whitespace-nowrap">
          Run Number
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("apiKey", {
      id: "apiKey",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white sm:pr-0 lg:pr-[300px] whitespace-nowrap">
          Key
        </p>
      ),
      cell: (info) => {
        const runNumber = info.row.original.runNumber as string;
        const isApiKeyVisible = showApiKey[runNumber];
        const isCopied = copiedState[runNumber] || false; // Check copied state for this row

        return (
          <div className="flex items-center">
            <button
              onClick={() => toggleApiKeyVisibility(runNumber)}
              className="mr-2"
            >
              {isApiKeyVisible ? (
                <FaEyeSlash className="text-gray-500 dark:text-white" />
              ) : (
                <FaEye className="text-gray-500 dark:text-white" />
              )}
            </button>
            <button
              onClick={() => handleCopyKey(runNumber, info.row.original.apiKey)}
              className="mr-2 text-gray-500 hover:text-gray-800"
            >
              {isCopied ? (
                <FaCheck className="text-gray-500" />
              ) : (
                <FaClipboard className="text-gray-500 " />
              )}
            </button>
            <p
              className="text-sm font-bold text-navy-700 dark:text-white cursor-pointer"
              onClick={() => handleCopyKey(runNumber, info.row.original.apiKey)}
            >
              {isApiKeyVisible ? info.getValue() : "••••••••••••"}
            </p>
          </div>
        );
      },
    }),
    columnHelper.accessor("count", {
      id: "count",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          จำนวนการใช้งาน
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("trialEndDate", {
      id: "trialEndDate",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          วันหมดอายุ
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {dayjs(info.getValue() || '').format('DD/MM/YYYY HH:mm:ss')}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("count", {
      id: "action",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cell: (info) => (
        <button
          className="w-24 rounded-lg bg-red-600 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-red-700 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
          onClick={() => handleButtonClick(info.row.original.runNumber)}
        >
          รีเซ็ต
        </button>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const pageCount = Math.ceil(data.length / pageSize);
  const currentPageData = data.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const handlePreviousFivePage = () => {
    setCurrentPage((prev) => Math.max(prev - 5, 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1));
  };

  const handleNextFivePage = () => {
    setCurrentPage((prev) => Math.min(prev + 5, pageCount - 1));
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (pageCount <= maxPagesToShow + 1) {
      for (let i = 0; i < pageCount; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`h-8 w-8 text-sm font-bold ${i === currentPage
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      if (currentPage < maxPagesToShow - 1) {
        for (let i = 0; i < maxPagesToShow; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`h-8 w-8 text-sm font-bold ${i === currentPage
                ? "rounded-lg bg-blue-600 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
                }`}
            >
              {i + 1}
            </button>
          );
        }

        pageNumbers.push(
          <button
            key="ellipsis1"
            onClick={handleNextFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = ">>")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        pageNumbers.push(
          <button
            key={pageCount - 1}
            onClick={() => handlePageClick(pageCount - 1)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === pageCount - 1
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            {pageCount}
          </button>
        );
      } else if (currentPage >= pageCount - maxPagesToShow + 1) {
        pageNumbers.push(
          <button
            key={0}
            onClick={() => handlePageClick(0)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === 0
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            1
          </button>
        );

        pageNumbers.push(
          <button
            key="ellipsis2"
            onClick={handlePreviousFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = "<<")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        for (let i = pageCount - maxPagesToShow; i < pageCount; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`h-8 w-8 text-sm font-bold ${i === currentPage
                ? "rounded-lg bg-blue-600 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
                }`}
            >
              {i + 1}
            </button>
          );
        }
      } else {
        pageNumbers.push(
          <button
            key={0}
            onClick={() => handlePageClick(0)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === 0
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            1
          </button>
        );

        pageNumbers.push(
          <button
            key="ellipsis3"
            onClick={handlePreviousFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = "<<")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handlePageClick(i)}
              className={`h-8 w-8 text-sm font-bold ${i === currentPage
                ? "rounded-lg bg-blue-600 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
                }`}
            >
              {i + 1}
            </button>
          );
        }

        pageNumbers.push(
          <button
            key="ellipsis4"
            onClick={handleNextFivePage}
            className="h-8 w-8 bg-gray-100 text-sm font-bold text-gray-600 hover:bg-gray-300 dark:text-white"
            onMouseEnter={(e: any) => (e.target.innerText = ">>")}
            onMouseLeave={(e: any) => (e.target.innerText = "...")}
          >
            ...
          </button>
        );

        pageNumbers.push(
          <button
            key={pageCount - 1}
            onClick={() => handlePageClick(pageCount - 1)}
            className={`h-8 w-8 text-sm font-bold ${currentPage === pageCount - 1
              ? "rounded-lg bg-blue-600 text-white"
              : "bg-gray-100 text-gray-600 hover:bg-gray-300 dark:text-white"
              }`}
          >
            {pageCount}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <Card extra={"w-full px-6"}>
      <div className="overflow-x-auto xl:overflow-x-hidden">
        <Table
          table={table}
          currentPage={currentPage}
          pageSize={pageSize}
          currentPageData={currentPageData}
        />
        <div className="my-4 flex justify-center">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 0}
            className={`h-8 w-8 rounded-l-full bg-gray-100 text-xl dark:text-white ${currentPage === 0
              ? "text-gray-600"
              : "text-navy-700 hover:bg-gray-300"
              }`}
          >
            {"<"}
          </button>
          <div className="flex bg-gray-100">{renderPageNumbers()}</div>
          <button
            onClick={handleNextPage}
            disabled={currentPage === pageCount - 1}
            className={`h-8 w-8 rounded-r-full bg-gray-100 text-xl dark:text-white ${currentPage === pageCount - 1
              ? "text-gray-600"
              : "text-navy-700 hover:bg-gray-300"
              }`}
          >
            {">"}
          </button>
        </div>
      </div>
    </Card>
  );
}

export default CheckTable;
