import Card from "components/card";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Select from 'react-select';

interface SlipOption {
  value: string;
  label: string;
}

const Input = () => {
  const [usernameFocused, setUsernameFocused] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [slips, setSlips] = useState<SlipOption[]>([]);
  const [selectedSlips, setSelectedSlips] = useState<string[]>([]);
  const [slipError, setSlipError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    // Fetch slips from database with options
    fetch(process.env.REACT_APP_API_URL + "getFileSlipSelect", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setSlips(data.files);
        } else {
          throw new Error(data.msg || 'เกิดข้อผิดพลาด');
        }
      })
      .catch((error) => {
        console.error('Error fetching slips:', error);
        Swal.fire({
          title: "เกิดข้อผิดพลาด!",
          text: error.message || "ไม่สามารถดึงข้อมูลสลิปได้",
          icon: "error",
          confirmButtonText: "ปิด",
        });
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const handleFocus = (setter: any) => () => setter(true);
  const handleBlur = (setter: any) => () => setter(false);

  const validateInputs = () => {
    let valid = true;

    if (!username) {
      setUsernameError("กรุณากรอกชื่อผู้ใช้งาน");
      valid = false;
    } else {
      setUsernameError("");
    }

    if (!selectedSlips.length) {
      setSlipError("กรุณาเลือกสลิปอย่างน้อย 1 รายการ");
      valid = false;
    } else {
      setSlipError("");
    }

    return valid;
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      let data = {
        username: username.trim(),
        slips: selectedSlips
      };

      fetch(process.env.REACT_APP_API_URL + "addUserSlip", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            Swal.fire({
              title: "เพิ่มสลิปสำเร็จ",
              text: json.message,
              icon: "success",
              confirmButtonText: "ปิด",
            }).then(() => {
              setUsername("");
              setSelectedSlips([]);
            });
          } else {
            Swal.fire({
              title: "เกิดข้อผิดพลาด!",
              text: json.message,
              icon: "error",
              confirmButtonText: "ปิด",
            });
          }
        })
        .catch((error) => {
          console.error('Error adding slips:', error);
          Swal.fire({
            title: "เกิดข้อผิดพลาด!",
            text: "ไม่สามารถเพิ่มสลิปได้",
            icon: "error",
            confirmButtonText: "ปิด",
          });
        });
    }
  };

  const fetchUserSlips = (username: string) => {
    if (!username) return;

    fetch(`${process.env.REACT_APP_API_URL}getUserSlips/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setSelectedSlips(data.slips || []);
        } else {
          setSelectedSlips([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching user slips:', error);
        setSelectedSlips([]);
      });
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    fetchUserSlips(newUsername);
  };

  return (
    <Card extra={"w-full px-6"}>
      <div className="w-full">
        <div className="relative mt-5">
          <input
            type="text"
            id="username"
            value={username}
            onFocus={handleFocus(setUsernameFocused)}
            onBlur={handleBlur(setUsernameFocused)}
            onChange={handleUsernameChange}
            className={`flex h-12 w-full items-center justify-center rounded-xl border bg-white p-3 text-sm outline-none ${usernameError ? "border-red-500" : ""
              }`}
          />
          <label
            htmlFor="username"
            className={`absolute left-3 px-1 text-sm transition-all duration-200 ease-in-out bg-white z-10 ${usernameFocused || username
              ? "left-2 top-[-0.6rem] text-xs"
              : "left-3 top-[1rem] text-sm"
              }`}
          >
            ชื่อผู้ใช้งาน
          </label>
          {usernameError && (
            <p className="mt-1 text-xs text-red-500">{usernameError}</p>
          )}
        </div>
        <div className="relative mt-4">
          <Select
            isMulti
            id="slips"
            value={slips.filter(slip => selectedSlips.includes(slip.value))}
            options={slips}
            isLoading={loading}
            onChange={(selected) => {
              setSelectedSlips(selected ? selected.map(option => option.value) : []);
              setSlipError("");
            }}
            placeholder=""
            className="rounded-xl"
            classNamePrefix="select"
            styles={{
              control: (base, state) => ({
                ...base,
                minHeight: '48px',
                borderRadius: '0.75rem',
                border: slipError ? '1px solid #EF4444' : '1px solid #e2e8f0',
                boxShadow: 'none',
                '&:hover': {
                  border: slipError ? '1px solid #EF4444' : '1px solid #e2e8f0',
                }
              }),
              multiValue: (base) => ({
                ...base,
                backgroundColor: '#EDF2F7',
                borderRadius: '0.5rem',
                padding: '2px',
              }),
              multiValueLabel: (base) => ({
                ...base,
                fontSize: '0.875rem',
              }),
              multiValueRemove: (base) => ({
                ...base,
                ':hover': {
                  backgroundColor: '#E53E3E',
                  color: 'white',
                },
                borderRadius: '0.375rem',
              }),
            }}
          />
          {slipError && (
            <p className="mt-1 text-xs text-red-500">{slipError}</p>
          )}
          <label
            htmlFor="slips"
            className={`absolute left-3 px-1 text-sm transition-all duration-200 ease-in-out bg-white z-10
              ${selectedSlips.length > 0
                ? "left-2 top-[-0.6rem] text-xs"
                : "left-3 top-[1rem] text-sm"
              }`}
          >
            สลิป
          </label>
        </div>
        <button
          className="linear mb-5 mt-4 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
          onClick={handleSubmit}
        >
          เพิ่มชุดสลิป
        </button>
      </div>
    </Card>
  );
};

export default Input;
